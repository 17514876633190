<template>
  <div>
    <h1 class="subtitle-1 grey--text">Программное обеспечение</h1>

    <v-container class="mb-5">
      <SoftwareCard v-for="software in software.softwares" :key="software.id" :software="software" />
    </v-container>

    <template v-if="page != 1">
      <router-link :to="{ name: 'software-list', query: { page: page - 1 } }" rel="prev">Prev Page</router-link>
      <template v-if="hasNextPage"> | </template>
    </template>
    <router-link v-if="hasNextPage" :to="{ name: 'software-list', query: { page: page + 1 } }" rel="next"> Next Page</router-link>
  </div>
</template>

<script>
import SoftwareCard from '@/components/SoftwareCard'
import { mapState } from 'vuex'
import store from '@/store/index'

function getPageSoftware(routeTo, next) {
  const currentPage = parseInt(routeTo.query.page || 1)
  store
    .dispatch('software/fetchPageSoftware', {
      page: currentPage
    })
    .then(() => {
      routeTo.params.page = currentPage
      next()
    })
}

export default {
  props: {
    page: {
      type: Number,
      required: true
    }
  },
  components: {
    SoftwareCard
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    getPageSoftware(routeTo, next)
  },
  beforeRouteUpdate(routeTo, routeFrom, next) {
    getPageSoftware(routeTo, next)
  },
  computed: {
    hasNextPage() {
      return this.software.softwaresTotal > this.page * this.perPage
    },
    ...mapState(['software', 'user'])
  }
}
</script>
